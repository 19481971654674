<template>
  <div class="md:flex md:gap-4 md:between divide-grey-70 <md:divide-y" :class="brandClasses">
    <base-collapse
      v-for="({ title, children }, key) in columns"
      :key
      class="w-full md:py-8"
      class-content="md:max-h-screen children:md:!visible"
      class-toggle="w-full md:hidden"
    >
      <template #toggle="{ expanded }">
        <span class="flex items-center subtitle-4 between <md:p-4">
          {{ title }}
          <vf-icon class="md:hidden" :dir="expanded ? 'up' : 'down'" name="chevron" size="lg" />
        </span>
      </template>

      <h2 class="subtitle-4 <md:hidden">
        {{ title }}
      </h2>

      <div class="<md:px-4 <md:pb-4">
        <ul class="text-sm md:mt-4 space-y-4 ">
          <li v-for="(child, j) in children" :key="j" class="space-y-4">
            <lazy-cms-section
              v-if="cmsFooter?.columns"
              :key="child.id"
              :section="{ name: child.id, items: [child] }"
            />
            <cms-footer-item v-else :content="child" :footer-item-l1-context-key="title" />
          </li>
        </ul>
      </div>

      <ul v-if="appStoreLinks && key === appStoreKey" class="md:mt-4 space-y-4 <md:px-4 <md:pb-4">
        <li v-for="({ icon, link, alt }, index) in appStoreLinks" :key="index" class="space-y-4">
          <base-link :to="link">
            <base-picture
              :alt
              height="36"
              :props-img="{ class: 'w-a' }"
              :src="icon"
              style="height: 2.25rem"
              width="108"
            />
          </base-link>
        </li>
      </ul>
    </base-collapse>
  </div>
</template>

<script lang="ts" setup>
import type { GetFooterData } from '#root/api/clients/internal/data-contracts'

const props = defineProps<{
  footerData: GetFooterData | null
}>()

const { brandClasses } = useAppConfig().components.vf.footer.columns

const { getFooter } = useCms()

const cmsFooter = await getFooter()

const columns = computed(() => cmsFooter?.columns || [])

const { storeIcons } = cmsFooter || {}
const appStoreLinks = storeIcons?.storeColumn
  ? props.footerData?.links?.appStores?.filter(({ name }) => storeIcons[name])
  : []
const appStoreKey = storeIcons?.storeColumn ? Number.parseInt(storeIcons.storeColumn) - 1 : 0
</script>
