<template>
  <div class="center self-start gap-2 b b-white bg-white p-1 i-flex">
    <base-picture
      v-for="{ method, picture } in cardPayments"
      :key="method"
      :alt="method"
      class="h-5"
      height="20"
      :props-img="{ class: 'w-a' }"
      :src="picture"
      width="36"
    />
    <!-- Paypal -->
    <base-picture
      v-if="$feature.allowPayPalAsPayment"
      alt="Paypal"
      class="h-5"
      height="20"
      src="/img/logos/paypal.svg"
      width="82"
    />
    <!-- Apple Pay -->
    <base-picture
      v-if="$feature.allowApplePayAsPayment"
      :alt="$t.applePayIconText"
      class="h-5"
      height="20"
      src="/img/logos/apple-pay.svg"
      width="47"
    />
    <!-- Klarna -->
    <base-picture
      v-if="$feature.allowKlarnaAsPayment && !auth.isEmployee"
      alt="Klarna"
      class="h-5"
      height="20"
      src="/img/logos/klarna-pink.svg"
      width="50"
    />
  </div>
</template>

<script lang="ts" setup>
const { cardTypes } = defineProps<{
  /**
   * available CreditCardTypes
   */
  cardTypes: string[]
}>()

const auth = useAuthStore()
const cardPayments = useCreditCards().getLogos(cardTypes)
</script>
